@use 'sats-ui-lib/tokens/spacing';
@use 'sats-ui-lib/tokens/lightmode';

.opening-hours {
  &__spinner {
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(white, 0.7);
  }

  &__club-select-wrapper {
    background-color: lightmode.$primary;
    padding: spacing.$l 0;
    color: lightmode.$on-primary;
  }

  &__club-select {
    max-width: 305px;
  }

  &__single-club-wrapper {
    >*+* {
      margin-top: spacing.$xxs;
    }
  }

  &__columns {
    position: relative;
    margin-top: spacing.$l;
    display: flex;
    flex-wrap: wrap;
    gap: spacing.$l;

    >*+* {
      flex-grow: 1;
    }

    @media (min-width: 800px) {
      flex-wrap: nowrap;
    }
  }
}
